@use '../base/colors';
@use '../utilities/variables' as vars;

.main-chat-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .main-chat-outlet {
    // height: calc(100svh - #{vars.$toolbar-height} - #{vars.$message-box-height});
    flex: 1 1 0;
    overflow: auto;
  }
}
