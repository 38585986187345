@use 'animations';
@use 'colors';
@use '../mixins/typography';

/*
 * HTML tags
 */
pre {
  background-color: colors.$secondary-lighter;
  border: 2px solid colors.$secondary-light;
  border-radius: 8px;
  padding: 1em;
}

code {
  @include typography.normal-text(12px, 18px, 0);
  font-family: monospace;
}

sub, sup {
  font-size: smaller;
  margin-left: 2px;
}

sup {
  vertical-align: super;
}

sub {
  vertical-align: sub;
}

/*
 * Custom clases
 */
.disabled-element {
  opacity: .3;
  cursor: default;
  pointer-events: none;
}

.filler {
  display: block;
  flex: 1 1 100%;
}
