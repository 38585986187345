@use '../base/colors';
@use '../mixins/typography';
@use '../utilities/variables' as vars;

form {
  @include typography.normal-text();
  display: flex;
  flex-direction: column;

  label {
    @include typography.bold-text();
  }

  input, textarea {
    border: 2px solid colors.$base-600;

    &:focus {
      border-color: colors.$primary;
    }

    &:disabled, &.disabled {
      border-color: colors.$base-800;
      color: colors.$base-400;
      cursor: default;
      pointer-events: none;
    }
  }

  .form-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    margin: 1em 0;
  }

  .form-fieldset {
    padding: 4px 0;
  }

  .form-field {
    margin-bottom: 1.5rem;
  }

  @media (min-width: vars.$layout-breakpoint-small) {
    .form-row {
      display: flex;
      align-items: center;
    }
    .form-label {
      width: 150px;
      text-align: right;
      margin-right: 1rem;
    }
    .form-input {
      flex: 1;
    }
    .form-field {
      margin-bottom: 1rem;
    }
  }
}
