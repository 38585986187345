@use '../base/colors';
@use '../mixins/typography';
@use '../utilities/variables' as vars;

.new-chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  background-color: colors.$secondary-light;

  .new-chat-container-title {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('/images/my-ai.jpg');

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: 100%;
    height: 100%;
    max-height: 152px;
    padding: 1em;
    align-self: flex-start;

    @media (max-width: vars.$layout-breakpoint-small) {
      display: none;
    }

    .title, .subtitle {
      color: colors.$base-1000;
      text-shadow: 0 0 7px colors.$base-000, 0 0 16px colors.$base-000;
    }

    .title {
      @include typography.normal-text(36px, 40px, -0.8px);
      font-weight: 500;
    }
  }

  @media (min-width: vars.$layout-breakpoint-medium) {
    .new-chat-grid-wrapper {
      flex: 1;
      grid-template-rows: auto;
      align-content: center;

    }
  }

  .new-chat-grid {
    padding: 16px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: row;
    grid-template-areas:
    '. .'
    '. .'
    '. .';

    @media (max-width: vars.$layout-breakpoint-small) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: '.';
    }

    .new-chat-suggestion {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 24px 12px;
      max-width: 392px;
      background-color: colors.$secondary-lighter;
      border-radius: 8px;

      @media (max-width: vars.$layout-breakpoint-small) {
        padding: 12px 12px 6px;
      }
      @media (min-width: vars.$layout-breakpoint-medium) {
        gap: 8px;
      }

      &-header {
        display: flex;
        gap: 11px;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        &-label {
          @include typography.bold-text(16px, 18px, -0.8px);

          @media (min-width: vars.$layout-breakpoint-medium) {
            min-height: 2lh;
            line-height: 24px;
            letter-spacing: -0.4px;
          }
        }

        i {
          color: colors.$primary;
          @include typography.normal-text();
        }
      }

      &-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-self: flex-end;

        i {
          font-size: 10px;
        }
      }
    }

  }

}
